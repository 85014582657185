<template>
  <div class="main-contaner">
    
    <Loader v-if="loading" />

    <div v-else class="main-contaner__content">
      <el-row>
        <el-col :span="24">
          <h1>{{title}}</h1>
        </el-col>
      </el-row>

      <ConditionCard
        v-for="item in conditionsInfo"
        :key="item.title"
        :title="item.title"
        :text="item.text"
        :icon="item.icon"
      />

    </div>

  </div>
</template>

<script>
import ConditionCard from '@/components/ConditionCard'
import { useMeta } from 'vue-meta'

export default {
  name: 'conditions',

  data: () => {
    return {
      title: 'Условия проживания',
      loading: true,
      conditionsInfo: [
        { icon: 'condition__icon', title: 'Бронирование',        text: 'Забронировать и снять квартиру в Пензе Вы можете, позвонив по телефону: +7(961)351-75-51 или оставить заявку на сайте. Если у вас изменились планы (время заезда, отмена поездки и т.п.) сообщите нам, пожалуйста, заранее. На 1-2 дня заранее квартиры не бронируем. В случае несогласованного сокращения сроков проживания мы оставляем за собой право увеличить стоимость арендной платы (если Вам предоставлялась скидка) или заменить арендуемый вариант другим.'},
        { icon: 'condition__icon', title: 'Заезд',               text: 'Если Вы бронируете квартиру заранее, за сутки до заселения необходимо позвонить и подтвердить бронь. Если заезд в день обращения, звонить необходимо за час до приезда. Наш администратор встретит Вас в квартире. При оформлении проживания Вам понадобится паспорт, подтверждающий Вашу личность. Оплатить проживание необходимо при заселении.'},
        { icon: 'condition__icon', title: 'Отчетные документы',  text: 'В случае необходимости, мы предоставляем отчётные документы в соответствии с федеральным законом-54: кассовый чек со штрих QR кодом (онлайн касса), счет, договор и акт. А также заверенные копии документов нашей организации: ИНН, свидетельство ОГРН, выписку из единого государственного реестра, уведомление из налогового органа о возможности применения упрощенной системы налогообложения. Также предоставляем подтверждение для организации. О необходимости документов нужно предупредить при бронировании.'},
        { icon: 'condition__icon', title: 'Выезд и продление',   text: 'Время выезда согласуется заранее – накануне. Выезд возможен не позднее 12:00, возможны индивидуальные условия. Если, проживая у нас, Вы решили продлить время пребывания, то нужно уведомить нас за сутки до ранее планировавшейся даты выезда. В таком случае мы можем сообщить Вам о возможности или невозможности продления срока аренды квартиры в Пензе.'},
        { icon: 'condition__icon', title: 'Безопасность',        text: 'Наши гости обязаны соблюдать правила техники безопасности в квартире и не оставлять без контроля включенные водопроводные краны, электроприборы, газовые и электрические плиты.'},
        { icon: 'condition__icon', title: 'Домашние животные',   text: 'Если Вы планируете заехать в квартиру с домашними животными, то нужно предварительно согласовать это при бронировании.'},
        { icon: 'condition__atte', title: 'Важная информация!',  text: 'Наши квартиры в Пензе предоставляются для аренды на сутки и более только командированным и иногородним и только для проживания. Мы не предоставляем квартиры жителям Пензы и Пензенской области. Мы не предоставляем квартиры для проведения праздников, дней рождения и прочих шумных мероприятий. В случае предоставления ложной информации о целях аренды квартиры, а также нарушений прав и интересов соседей, нарушители будут выселены немедленно и без возврата арендной платы.'}
      ]
    }
  },

  mounted() {

    useMeta({
      title: 'Условия проживания',
      description: 'Условия проживания в наших квартирах. Обязательно ознакомьтесь с правилами. Снять квартиру в Пензе ☎ +7 (961) 351-75-51',
      og: {
        description: 'Условия проживания в наших квартирах. Обязательно ознакомьтесь с правилами. Снять квартиру в Пензе ☎ +7 (961) 351-75-51',
        image: require('@/assets/images/og-image.jpg'),
        url: 'sweethome58.ru/conditions',
        title: 'Условия проживания | Посуточная аренда квартир'
      }
    })

    this.loading = false
  },

  components: {
    ConditionCard
  }
}
</script>